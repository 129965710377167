@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/OpenSans-Light.ttf");
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/OpenSans-Regular.ttf");
}

body {
  margin: 0;
  font-family: 'Open Sans', Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
